import Timeago from '@stimulus-components/timeago';

import AppearController from './appear-controller';
import { application } from './application';
import ChartsCountsController from './charts-counts-controller';
import ColorPickerController from './color-picker-controller';
import DataAttributeController from './data-attribute-controller';
import DelayedRemoveController from './delayed-remove-controller';
import DialogController from './dialog-controller';
import DropdownController from './dropdown-controller';
import GeocoderCacheController from './geocoder-cache-controller';
import NearbyLocationsController from './nearby-locations-controller';
import ProgressController from './progress-controller';
import ProjectionsController from './projections-controller';
import RemoveController from './remove-controller';
import ReportController from './report-controller';
import ScheduleActivityController from './schedule-activity-controller';
import ScheduleAssignmentController from './schedule-assignment-controller';
import ScheduleDayController from './schedule-day-controller';
import ScheduleDaySelectController from './schedule-day-select-controller';
import ScheduleFrequencyChartController from './schedule-frequency-chart-controller';
import ScheduleGroupController from './schedule-group-controller';
import ScheduleLocationController from './schedule-location-controller';
import TableController from './table-controller';
import TransactionSummaryController from './transaction-summary-controller';
import TransportationSummaryController from './transportation-summary-controller';

application.register('appear', AppearController);
application.register('charts-counts', ChartsCountsController);
application.register('color-picker', ColorPickerController);
application.register('data-attribute', DataAttributeController);
application.register('delayed-remove', DelayedRemoveController);
application.register('dialog', DialogController);
application.register('dropdown', DropdownController);
application.register('geocoder-cache', GeocoderCacheController);
application.register('nearby-locations', NearbyLocationsController);
application.register('progress', ProgressController);
application.register('projections', ProjectionsController);
application.register('remove', RemoveController);
application.register('report', ReportController);
application.register('schedule-activity', ScheduleActivityController);
application.register('schedule-assignment', ScheduleAssignmentController);
application.register('schedule-day-select', ScheduleDaySelectController);
application.register('schedule-day', ScheduleDayController);
application.register('schedule-frequency-chart', ScheduleFrequencyChartController);
application.register('schedule-group', ScheduleGroupController);
application.register('schedule-location', ScheduleLocationController);
application.register('table', TableController);
application.register('timeago', Timeago);
application.register('transaction-summary', TransactionSummaryController);
application.register('transportation-summary', TransportationSummaryController);
