import { Controller } from '@hotwired/stimulus';
import mapboxgl, { LngLatBounds, Map, Marker, NavigationControl } from 'mapbox-gl';

import { createMarker } from '../utilities/marker.ts';

export default class GeocoderCacheController extends Controller {
  static targets = [
    'map',
    'physicalLatitude',
    'physicalLongitude',
    'navigationLatitude',
    'navigationLongitude',
    'userModified',
  ];
  static values = { apiKey: String };

  declare apiKeyValue: string;
  declare readonly mapTarget: HTMLElement;
  declare readonly physicalLatitudeTarget: HTMLInputElement;
  declare readonly physicalLongitudeTarget: HTMLInputElement;
  declare readonly navigationLatitudeTarget: HTMLInputElement;
  declare readonly navigationLongitudeTarget: HTMLInputElement;
  declare readonly userModifiedTarget: HTMLInputElement;

  map: Map | undefined;
  markers: Record<string, Marker> = {};

  connect() {
    this.initializeMap();
    this.loadMarkers();
  }

  disconnect() {
    if (this.markers) this.removeAllMarkers();
    if (this.map) this.map.remove();
  }

  initializeMap() {
    mapboxgl.accessToken = this.apiKeyValue;

    this.map = new Map({
      container: this.mapTarget,
      style: 'mapbox://styles/mapbox/streets-v12',
      center: this.physicalCenter,
      zoom: 12,
    });

    this.map.addControl(new NavigationControl(), 'top-right');
  }

  addMarker(
    id: string,
    location: [number, number],
    color = 'blue',
    icon = 'circle',
    onDrag: (location: [number, number]) => void,
  ) {
    if (!this.map) return;

    const markerElement = createMarker(color, icon);
    const marker = new Marker({ element: markerElement, offset: [0, -14], draggable: true }).setLngLat(location);
    marker.addTo(this.map);

    marker.on('drag', () => {
      const lngLat = marker.getLngLat();
      if (onDrag) onDrag([lngLat.lng, lngLat.lat]);
    });
    this.markers[id] = marker;
  }

  removeAllMarkers() {
    for (const marker of Object.values(this.markers)) {
      marker.remove();
    }
    this.markers = {};
  }

  get physicalCenter(): [number, number] {
    const latitude = Number.parseFloat(this.physicalLatitudeTarget.value);
    const longitude = Number.parseFloat(this.physicalLongitudeTarget.value);

    return [longitude, latitude];
  }

  get navigationCenter(): [number, number] {
    const latitude = Number.parseFloat(this.navigationLatitudeTarget.value);
    const longitude = Number.parseFloat(this.navigationLongitudeTarget.value);

    return [longitude, latitude];
  }

  physicalCenterChanged(location: [number, number]) {
    this.physicalLatitudeTarget.value = location[1].toFixed(6);
    this.physicalLongitudeTarget.value = location[0].toFixed(6);
    this.userModifiedTarget.value = 'true';
  }

  navigationCenterChanged(location: [number, number]) {
    this.navigationLatitudeTarget.value = location[1].toFixed(6);
    this.navigationLongitudeTarget.value = location[0].toFixed(6);
    this.userModifiedTarget.value = 'true';
  }

  loadMarkers() {
    this.removeAllMarkers();

    if (!this.map) return;

    this.addMarker('physical', this.physicalCenter, 'blue', 'house', this.physicalCenterChanged.bind(this));
    this.addMarker('navigation', this.navigationCenter, 'green', 'road', this.navigationCenterChanged.bind(this));

    this.fitBoundsToMarkers();
  }

  fitBoundsToMarkers() {
    const markers = Object.values(this.markers);

    if (!this.map) return;

    if (markers.length === 0) {
      this.map.setCenter(this.physicalCenter);
      this.map.setZoom(12);
      return;
    }

    const bounds = new LngLatBounds();
    for (const marker of Object.values(this.markers)) {
      bounds.extend(marker.getLngLat());
    }
    this.map.fitBounds(bounds, { padding: 50 });
  }
}
