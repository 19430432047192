export function createSVG(tagName: string, attributes: Record<string, string | number>, container?: Element): Element {
  const element = document.createElementNS('http://www.w3.org/2000/svg', tagName);
  for (const name of Object.keys(attributes)) {
    element.setAttributeNS(null, name, String(attributes[name]));
  }
  if (container) container.append(element);
  return element;
}

function circlePath(svg: Element) {
  return createSVG('circle', { fill: 'white', cx: 13.5, cy: 13.5, r: 5.5 }, svg);
}

function userPath(svg: Element) {
  createSVG(
    'path',
    {
      fill: 'white',
      d: 'M20.4,20c0,.2-.3.3-.5.3H7c-.3,0-.5-.2-.5-.5,0,0,0-.2,0-.3,1-1.8,2.6-3,4.4-3.6-2.3-1.4-3.1-4.3-1.7-6.6,1.4-2.3,4.3-3.1,6.6-1.7,2.3,1.4,3.1,4.3,1.7,6.6-.4.7-1,1.3-1.7,1.7,1.8.6,3.4,1.9,4.4,3.6,0,.2,0,.4,0,.5Z',
    },
    svg,
  );
}

function usersPath(svg: Element) {
  createSVG(
    'path',
    {
      fill: 'white',
      d: 'M8.7,14.4c0,.2-.1.3-.3.3,0,0,0,0,0,0h-3.3c-.3,0-.5-.2-.6-.5,0-.2,0-.4.1-.5.5-.7,1.2-1.3,2-1.6-1.2-1.1-1.3-3-.2-4.2,1.1-1.2,3-1.3,4.2-.2.5.5.8,1.1.9,1.7,0,.1,0,.3-.2.3-1.7.8-2.8,2.5-2.8,4.4,0,0,0,.2,0,.3ZM22.4,13.7c-.5-.7-1.2-1.3-2-1.6,1.2-1.1,1.3-3,.2-4.2-1.1-1.2-3-1.3-4.2-.2-.5.5-.8,1.1-.9,1.7,0,.1,0,.3.2.3,1.7.8,2.8,2.5,2.8,4.4,0,0,0,.2,0,.3,0,.2.1.3.3.3,0,0,0,0,0,0h3.3c.3,0,.5-.2.6-.5,0-.2,0-.4-.1-.5h0ZM15.7,17c1.6-1.2,1.9-3.5.7-5-1.2-1.6-3.5-1.9-5-.7-1.6,1.2-1.9,3.5-.7,5,.2.3.4.5.7.7-1.1.5-1.9,1.2-2.5,2.2-.2.3,0,.7.2.8,0,0,.2,0,.3,0h8.4c.3,0,.6-.3.6-.6,0-.1,0-.2,0-.3-.6-1-1.5-1.8-2.5-2.2h0Z',
    },
    svg,
  );
}

function roadPath(svg: Element) {
  createSVG(
    'path',
    {
      fill: 'white',
      d: 'M22.1,10.4h-2.2l-3.4-3.4c-.2-.2-.6-.4-.9-.4H6.8c-.4,0-.8.2-1,.6l-2.3,3.5c0,.1-.1.2-.1.3v3.7c0,.7.6,1.2,1.2,1.2h1.3c.3,1.3,1.7,2.1,3,1.8.9-.2,1.6-.9,1.8-1.8h5.1c.3,1.3,1.7,2.1,3,1.8.9-.2,1.6-.9,1.8-1.8h1.3c.7,0,1.2-.6,1.2-1.2v-3.1c0-.7-.6-1.2-1.2-1.2ZM6.8,7.9h8.8l2.5,2.5H5.2l1.7-2.5ZM8.4,16.6c-.7,0-1.2-.6-1.2-1.2s.6-1.2,1.2-1.2,1.2.6,1.2,1.2-.6,1.2-1.2,1.2ZM18.3,16.6c-.7,0-1.2-.6-1.2-1.2s.6-1.2,1.2-1.2,1.2.6,1.2,1.2-.6,1.2-1.2,1.2Z',
    },
    svg,
  );
}

function housePath(svg: Element) {
  createSVG(
    'path',
    {
      fill: 'white',
      d: 'M21,12.3v7.5c0,.3-.3.6-.6.6h-4.4c-.3,0-.6-.3-.6-.6v-4.1c0-.2-.1-.3-.3-.3h-3.1c-.2,0-.3.1-.3.3v4.1c0,.3-.3.6-.6.6h-4.4c-.3,0-.6-.3-.6-.6v-7.5c0-.3.1-.7.4-.9l6.3-6.3c.5-.5,1.3-.5,1.8,0l6.3,6.3c.2.2.4.6.4.9Z',
    },
    svg,
  );
}

function textPath(svg: Element, text: string) {
  createSVG(
    'text',
    {
      x: 13.5,
      y: 18,
      'font-size': 13,
      'font-weight': 'Bold',
      'text-anchor': 'middle',
      fill: 'white',
    },
    svg,
  ).append(document.createTextNode(text));
}

export function popupOffset(): Record<string, [number, number]> {
  const markerHeight = 41 - 5.8 / 2;
  const markerRadius = 13.5;
  const linearOffset = Math.sqrt(Math.pow(markerRadius, 2) / 2);
  return {
    top: [0, 0],
    'top-left': [0, 0],
    'top-right': [0, 0],
    bottom: [0, -markerHeight],
    'bottom-left': [linearOffset, (markerHeight - markerRadius + linearOffset) * -1],
    'bottom-right': [-linearOffset, (markerHeight - markerRadius + linearOffset) * -1],
    left: [markerRadius, (markerHeight - markerRadius) * -1],
    right: [-markerRadius, (markerHeight - markerRadius) * -1],
  };
}

export function createMarker(color: string, shape = 'circle', text = '') {
  const element = document.createElement('div');

  // create default map marker SVG

  const scale = 1;
  const DEFAULT_HEIGHT = 41;
  const DEFAULT_WIDTH = 27;

  const svg = createSVG(
    'svg',
    {
      display: 'block',
      height: `${DEFAULT_HEIGHT * scale}px`,
      width: `${DEFAULT_WIDTH * scale}px`,
      viewBox: `0 0 ${DEFAULT_WIDTH} ${DEFAULT_HEIGHT}`,
    },
    element,
  );

  const gradient = createSVG('radialGradient', { id: 'shadowGradient' }, createSVG('defs', {}, svg));
  createSVG('stop', { offset: '10%', 'stop-opacity': 0.4 }, gradient);
  createSVG('stop', { offset: '100%', 'stop-opacity': 0.05 }, gradient);
  createSVG('ellipse', { cx: 13.5, cy: 34.8, rx: 10.5, ry: 5.25, fill: 'url(#shadowGradient)' }, svg); // shadow

  createSVG(
    'path',
    {
      // marker shape
      fill: color,
      d: 'M27,13.5C27,19.07 20.25,27 14.75,34.5C14.02,35.5 12.98,35.5 12.25,34.5C6.75,27 0,19.22 0,13.5C0,6.04 6.04,0 13.5,0C20.96,0 27,6.04 27,13.5Z',
    },
    svg,
  );
  createSVG(
    'path',
    {
      // border
      opacity: 0.25,
      d: 'M13.5,0C6.04,0 0,6.04 0,13.5C0,19.22 6.75,27 12.25,34.5C13,35.52 14.02,35.5 14.75,34.5C20.25,27 27,19.07 27,13.5C27,6.04 20.96,0 13.5,0ZM13.5,1C20.42,1 26,6.58 26,13.5C26,15.9 24.5,19.18 22.22,22.74C19.95,26.3 16.71,30.14 13.94,33.91C13.74,34.18 13.61,34.32 13.5,34.44C13.39,34.32 13.26,34.18 13.06,33.91C10.28,30.13 7.41,26.31 5.02,22.77C2.62,19.23 1,15.95 1,13.5C1,6.58 6.58,1 13.5,1Z',
    },
    svg,
  );

  if (shape === 'circle') circlePath(svg); // circle
  if (shape === 'user') userPath(svg); // user
  if (shape === 'users') usersPath(svg); // users
  if (shape === 'road') roadPath(svg); // road
  if (shape === 'house') housePath(svg); // house
  if (shape === 'text') textPath(svg, text); // text

  return element;
}
