/* eslint-disable prettier/prettier */
import { Calendar, DateSelectArg, EventClickArg, EventInput, EventSourceFuncArg } from '@fullcalendar/core';
import interactionPlugin from '@fullcalendar/interaction';
import { ResourceFuncArg } from '@fullcalendar/resource/index.js';
import resourceTimelinePlugin from '@fullcalendar/resource-timeline';
import { Controller } from '@hotwired/stimulus';
import { FrameElement } from '@hotwired/turbo';

import { GroupPlanGroup, ScheduleActivity, ScheduleAssignment, ScheduleAssignmentUpdatedEvent, ScheduleLocation } from '../types/app';

export default class ScheduleDayController extends Controller<HTMLElement> {
  calendar: Calendar | undefined;

  static values = {
    editable: { type: Boolean, default: false },
    season: Number,
    dayId: Number,
    type: String,
  };

  declare editableValue: boolean;
  declare seasonValue: number;
  declare dayIdValue: number;
  declare typeValue: string;

  connect() {
    this.calendar = new Calendar(this.element, {
      height: '100%',
      plugins: [interactionPlugin, resourceTimelinePlugin],
      initialView: 'timeGridScheduler',
      headerToolbar: { left: '', center: '', right: '' },
      initialDate: '2000-01-01',
      resourceOrder: this.resourceOrder,
      selectable: this.editableValue,
      views: {
        timeGridScheduler: {
          type: 'resourceTimeline',
          slotMinTime: '09:00:00',
          slotMaxTime: '16:30:00',
          slotDuration: '00:15:00',
          resourceAreaWidth: '15%',
          resourceGroupField: this.resourceGroupField,
        },
      },
      select: this.select.bind(this),
      eventClick: this.eventClick.bind(this),
      events: this.fetchEvents.bind(this),
      resources: this.fetchResources.bind(this),
      resourceGroupLabelContent: function (argument) {
        const name = argument.groupValue as string || '';
        return {'html': `${name.split(' ').slice(1).join(' ')}`}
}
    });
    this.calendar.render();
  }

  disconnect() {
    this.calendar?.destroy();
  }

  get resourceOrder() {
    switch (this.typeValue) {
      case 'group': {
        return 'divisionName,orderIndex';
      }
      case 'location': {
        return 'area,title';
      }
      case 'activity': {
        return 'activityTypeName,title';
      }
      default: {
        return 'orderIndex';
      }
    }
  }

  get resourceGroupField() {
    switch (this.typeValue) {
      case 'group': {
        return 'divisionName';
      }
      case 'location': {
        return 'area';
      }
      case 'activity': {
        return 'activityTypeName';
      }
      default: {
        return 'divisionName';
      }
    }
  }

  async fetchEvents(
    _info: EventSourceFuncArg,
    successCallback: (eventInputs: EventInput[]) => void,
    failureCallback: (error: Error) => void,
  ) {
    const url = this.eventSourceURL;

    try {
      const response = await fetch(url);
      if (!response.ok) {
        failureCallback(new Error(response.statusText));
      }

      const json = (await response.json()) as ScheduleAssignment[];

      const eventInputs = json.map((assignment) => {
        return this.parseScheduleAssignment(assignment);
      });

      return successCallback(eventInputs);
    } catch (error: unknown) {
      failureCallback(error as Error);
    }

    return successCallback([]);
  }

  async fetchResources(
    _info: ResourceFuncArg,
    successCallback: (eventInputs: EventInput[]) => void,
    failureCallback: (error: Error) => void,
  ) {
    switch (this.typeValue) {
      case 'group': {
        return this.fetchGroupResources(_info, successCallback, failureCallback);
      }
      case 'location': {
        return this.fetchLocationResources(_info, successCallback, failureCallback);
      }
      case 'activity': {
        return this.fetchActivityResources(_info, successCallback, failureCallback);
      }
      default: {
        return successCallback([]);
      }
    }
  }

  async fetchGroupResources(
    _info: ResourceFuncArg,
    successCallback: (eventInputs: EventInput[]) => void,
    failureCallback: (error: Error) => void,
  ) {
    const url = new URL('/scheduling/group_plan_groups.json', window.location.origin);
    url.searchParams.append('q[season_id_eq]', this.seasonValue.toString());

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const json = (await response.json()) as GroupPlanGroup[];

      const resources = json
        .map((group) => {
          return this.parseGroupAsResource(group);
        });

      return successCallback(resources);
    } catch (error: unknown) {
      failureCallback(error as Error);
    }

    return successCallback([]);
  }

  async fetchActivityResources(
    _info: ResourceFuncArg,
    successCallback: (eventInputs: EventInput[]) => void,
    failureCallback: (error: Error) => void,
  ) {
    const url = new URL('/scheduling/schedule_activities.json', window.location.origin);
    url.searchParams.append('q[season_id_eq]', this.seasonValue.toString());

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const json = (await response.json()) as ScheduleActivity[];

      const resources = json
        .map((activity) => {
          return this.parseActivityAsResource(activity);
        });

      return successCallback(resources);
    } catch (error: unknown) {
      failureCallback(error as Error);
    }

    return successCallback([]);
  }

  async fetchLocationResources(
    _info: ResourceFuncArg,
    successCallback: (eventInputs: EventInput[]) => void,
    failureCallback: (error: Error) => void,
  ) {
    const url = new URL('/scheduling/schedule_locations.json', window.location.origin);
    url.searchParams.append('q[season_id_eq]', this.seasonValue.toString());

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(response.statusText);
      }

      const json = (await response.json()) as ScheduleLocation[];

      const resources = json
        .map((location) => {
          return this.parseLocationAsResource(location);
        });

      return successCallback(resources);
    } catch (error: unknown) {
      failureCallback(error as Error);
    }

    return successCallback([]);
  }

  eventName(assignment: ScheduleAssignment) {
    switch (this.typeValue) {
      case 'group': {
        return this.groupEventName(assignment);
      }
      case 'location': {
        return this.locationEventName(assignment);
      }
      case 'activity': {
        return this.activityEventName(assignment);
      }
      default: {
        return 'event';
      }
    }
  }

  groupEventName(assignment: ScheduleAssignment) {
    const components = assignment.group_schedule_display_name ?? [];

    if (components.length === 0) {
      return 'event';
    }

    return components[0];
  }

  locationEventName(assignment: ScheduleAssignment) {
    const components = assignment.location_schedule_display_name ?? [];

    if (components.length === 0) {
      return 'event';
    }

    return components.join(' - ');
  }

  activityEventName(assignment: ScheduleAssignment) {
    const components = assignment.activity_schedule_display_name ?? [];

    if (components.length === 0) {
      return 'event';
    }

    return components.join(' - ');
  }

  eventTextColor(color: string) {
    const r = Number.parseInt(color.slice(1, 3), 16);
    const g = Number.parseInt(color.slice(3, 5), 16);
    const b = Number.parseInt(color.slice(5, 7), 16);
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? 'black' : 'white';
  }

  parseScheduleAssignment(assignment: ScheduleAssignment): EventInput {
    if (assignment.schedule_day_id !== this.dayIdValue) {
      return {};
    }

    let resourceId: string | undefined;
    switch (this.typeValue) {
      case 'group': {
        resourceId = assignment.group_plan_group_id?.toString();
        break;
      }
      case 'location': {
        resourceId = assignment.schedule_location_id?.toString();
        break;
      }
      case 'activity': {
        resourceId = assignment.schedule_activity_id?.toString();
        break;
      }
    }

    return {
      id: assignment.id.toString(),
      resourceId: resourceId,
      start: `${assignment.start_time}`,
      end: `${assignment.end_time}`,
      title: this.eventName(assignment),
      backgroundColor: assignment.color ?? '#DDDDDD',
      borderColor: assignment.color ?? '#DDDDDD',
      textColor: this.eventTextColor(assignment.color ?? '#DDDDDD'),
    };
  }

  parseGroupAsResource(group: GroupPlanGroup): EventInput {
    return {
      id: group.id.toString(),
      title: group.group_name,
      extendedProps: {
        divisionName: `${group.division_order_index} ${group.division_name}`,
        divisionOrderIndex: group.division_order_index,
        startTime: group.start_time,
        endTime: group.end_time,
        orderIndex: group.order_index,
      },
    };
  }

  parseActivityAsResource(activity: ScheduleActivity): EventInput {
    return {
      id: activity.id.toString(),
      title: activity.name,
      extendedProps: {
        activityTypeName: `${activity.schedule_activity_type_order_index} ${activity.schedule_activity_type_name}`,
        activityTypeOrderIndex: activity.schedule_activity_type_order_index,
        startTime: activity.start_time,
        endTime: activity.end_time,
      },
    };
  }

  parseLocationAsResource(location: ScheduleLocation): EventInput {
    return {
      id: location.id.toString(),
      title: location.name,
      extendedProps: {
        area: `X ${location.area}`,
      },
    };
  }


  get eventSourceURL() {
    const url = new URL('/scheduling/schedule_assignments.json', window.location.origin);
    url.searchParams.append('season_id', this.seasonValue.toString());
    url.searchParams.append('schedule_day_id', this.dayIdValue.toString());

    if (this.calendar) {
      for (const resource of this.calendar.getResources()) {
        url.searchParams.append('schedule_day_ids[]', resource.id);
      }
    }

    return url.href;
  }

  assignmentCreated(info: ScheduleAssignmentUpdatedEvent) {
    this.assignmentUpdated(info);
  }

  assignmentUpdated(info: ScheduleAssignmentUpdatedEvent) {
    const rawEvent = JSON.parse(info.detail.content) as ScheduleAssignment;

    this.calendar?.getEventById(rawEvent.id.toString())?.remove();
    this.calendar?.addEvent(this.parseScheduleAssignment(rawEvent), true);
  }

  assignmentDestroyed(info: ScheduleAssignmentUpdatedEvent) {
    const { id } = JSON.parse(info.detail.content) as { id: number };

    this.calendar?.getEventById(id.toString())?.remove();
  }

  select(info: DateSelectArg) {
    if (!this.#modalTurboFrame) return;
    if (!info.resource) return;

    const url = new URL('/scheduling/schedule_assignments/new', window.location.origin);
    url.searchParams.append('type', this.typeValue);
    url.searchParams.append('season_id', this.seasonValue.toString());
    url.searchParams.append('schedule_day_id', this.dayIdValue.toString());
    url.searchParams.append('start_time', info.start.toTimeString().slice(0, 8));
    url.searchParams.append('end_time', info.end.toTimeString().slice(0, 8));

    switch (this.typeValue) {
      case 'group': {
        url.searchParams.append('group_plan_group_id', info.resource.id);
        break;
      }
      case 'location': {
        url.searchParams.append('schedule_location_id', info.resource.id);
        break;
      }
      case 'activity': {
        url.searchParams.append('schedule_activity_id', info.resource.id);
        break;
      }
    }

    this.#modalTurboFrame.src = url.href;

    this.calendar?.unselect();
  }

  eventClick(info: EventClickArg) {
    if (!this.editableValue) return;
    if (!this.#modalTurboFrame) return;

    const url = new URL(`/scheduling/schedule_assignments/${info.event.id}/edit`, window.location.origin);
    url.searchParams.append('type', this.typeValue);

    this.#modalTurboFrame.src = url.href;
  }

  get #modalTurboFrame() {
    return document.querySelector<FrameElement>('turbo-frame[id="modal"]');
  }
}
