import { Controller } from '@hotwired/stimulus';

import { debounce } from '../utilities/debounce';

export default class ScheduleAssignmentController extends Controller<HTMLElement> {
  static targets = ['form', 'loader', 'fields'];

  declare formTarget: HTMLFormElement;
  declare fieldsTargets: HTMLInputElement[];
  declare loaderTarget: HTMLElement;

  static values = {
    delay: {
      type: Number,
      default: 250,
    },
  };

  declare delayValue: number;

  initialize(): void {
    this.refreshForm = this.refreshForm.bind(this);
  }

  connect(): void {
    if (this.delayValue > 0) {
      // eslint-disable-next-line @typescript-eslint/unbound-method
      this.refreshForm = debounce(this.refreshForm, this.delayValue);
    }
  }

  groupChanged() {
    this.refreshForm();
  }

  dayChanged() {
    this.refreshForm();
  }

  timeChanged() {
    this.refreshForm();
  }

  refreshForm() {
    this.loaderTarget.classList.remove('hidden');

    const refresh = document.createElement('input');
    refresh.type = 'hidden';
    refresh.name = 'refresh';
    refresh.value = 'true';

    this.formTarget.append(refresh);
    this.formTarget.requestSubmit();
    refresh.remove();
  }

  changed(event: Event) {
    const target = event.target as HTMLInputElement;
    const fieldGroup = target.dataset.scheduleAssignmentGroup;

    for (const field of this.fieldsTargets) {
      if (field.dataset.scheduleAssignmentGroup !== fieldGroup) {
        field.value = '';
        field.checked = false;
        field.dispatchEvent(new Event('cleared'));
      }
    }
  }
}
